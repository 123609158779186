import ProstackLogoIcon from "../icons/prostack-logo-icon";
import { Button } from "../ui/button";
import Link from "@/components/atoms/link";
import LoadingThreeDots from "../atoms/loading-three-dots";
import { LINK_SUPPORT_DOCUMENTATION } from "@/lib/links";
import { LogIn } from "lucide-react";
export default function Login({
  errorText,
  loading,
  handleLogin
}: {
  errorText?: string;
  loading: boolean;
  handleLogin: () => void;
}) {
  return <div className="flex h-screen w-full items-center justify-center bg-background" data-sentry-component="Login" data-sentry-source-file="login.tsx">
      <div className="mx-4 w-full max-w-md space-y-6">
        <div className="flex items-center justify-center">
          <ProstackLogoIcon data-sentry-element="ProstackLogoIcon" data-sentry-source-file="login.tsx" />
        </div>
        <div className="text-center">
          <h1 className="text-3xl font-bold">Welcome back!</h1>
          <p className="text-muted-foreground">
            Sign in to manage your hosting account.
          </p>
        </div>
        {errorText && <div className="rounded-md bg-red-100 p-4 text-red-900">
            <p>Unable to login: {errorText}</p>
          </div>}
        <Button disabled={loading} className="w-full justify-center gap-2" onClick={handleLogin} variant="outline" data-sentry-element="Button" data-sentry-source-file="login.tsx">
          {loading ? <>
              Signing in <LoadingThreeDots variant={"outline"} />
            </> : <>
              <LogIn className="h-6 w-6" />
              Sign in via the portal
            </>}
        </Button>
        <p className="text-center text-muted-foreground">
          {"If you're having trouble logging in, please "}
          <Link href={LINK_SUPPORT_DOCUMENTATION} data-sentry-element="Link" data-sentry-source-file="login.tsx">contact support</Link>.
        </p>
      </div>
    </div>;
}